import ApiService from '@/core/services/ApiService';
import JwtService from '@/core/services/JwtService';
import { CatalogItem } from '@/interfaces/Catalog';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

export interface CatalogInfo {
  errors: unknown;
  catalog: CatalogItem[];
}

@Module
export default class CatalogModule extends VuexModule implements CatalogInfo {
  errors = {};
  catalog: CatalogItem[] = [];
  selectedCatalog = '';

  get currentCatalog(): CatalogItem[] {
    return this.catalog;
  }

  get selectedCurrentCatalog(): string {
    return this.selectedCatalog;
  }

  @Mutation
  [Mutations.SET_ERROR](error: object): void {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_CATALOG](data: CatalogItem[]): void {
    this.catalog = data;
  }

  @Mutation
  [Mutations.SET_SELECTED_CATALOG](name: string): void {
    this.selectedCatalog = name;
  }

  @Action
  [Actions.GET_CATALOG](): Promise<void> {
    ApiService.setHeader();
    return ApiService.get('catalog')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CATALOG, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }
}
