import { CookieComponent } from '@/assets/ts/components/_CookieComponent';
import ApiService from '@/core/services/ApiService';
import JwtService from '@/core/services/JwtService';
import { User } from '@/interfaces/User';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  users: User[];
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  users: User[] = [];
  isAuthenticated = !!JwtService.getToken();
  userGroup: string[] = [];
  managerSelectedUser = '';

  /**
   * Get current user object
   * @returns User
   */
  get getCurrentUser(): User {
    return this.user;
  }

  /**
   * Get manager selected user object
   * @returns User
   */
  get getCurrentManagerSelectedUser(): string {
    return this.managerSelectedUser;
  }

  /**
   * Get current user object
   * @returns User
   */
  get getCurrentUserGroups(): object {
    return this.userGroup;
  }
  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get all user object
   * @returns User
   */
  get allUsers(): User[] {
    return this.users;
  }

  /**
   * Get authentication errors
   * @returns array
   */
  get getErrors(): any {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error: any): any {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user: User): void {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = {};

    window.localStorage.setItem('isAuthenticated', 'true');
  }

  @Mutation
  [Mutations.SET_USER](user: User): void {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_USER_GROUPS](groups: string[]): void {
    this.userGroup = groups;
  }

  @Mutation
  [Mutations.SET_SLEEK_USER](ssoToken: string): void {
    window.$sleek.setUser({ token: ssoToken });
  }

  @Mutation
  [Mutations.SET_ALL_USERS](users: User[]): void {
    this.users = users;
  }

  @Mutation
  [Mutations.SET_MANAGER_SELECTED_USER](ownerId: string): void {
    this.managerSelectedUser = ownerId;
  }

  @Mutation
  [Mutations.PURGE_AUTH](): void {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    window.localStorage.removeItem('userInfo');
    window.localStorage.removeItem('isAuthenticated');
    CookieComponent.delete('id_token');
    CookieComponent.delete('secure_access_token');
    CookieComponent.delete('userId');
    window.$sleek.resetUser();
  }

  @Action
  [Actions.LOGINLOCAL](user: User): void {
    this.context.commit(Mutations.SET_AUTH, user);
    this.context.commit(Mutations.SET_USER_GROUPS, user.groups);
  }

  @Action
  [Actions.LOGINSSO](id_token: User): Promise<void> {
    ApiService.setHeader();
    return ApiService.get('user/' + id_token.email)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
        this.context.commit(Mutations.SET_USER_GROUPS, data.groups);
        this.context.dispatch(Actions.LOGINSLEEKSSO);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.LOGINSLEEKSSO](): Promise<void> {
    ApiService.setHeader();
    return ApiService.get('sleekplan/token')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SLEEK_USER, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.LOGIN](user: User): Promise<void> {
    ApiService.setHeader();
    return ApiService.get('user/' + user.email)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.LOGOUT](): void {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.GET_ALL_USERS](): Promise<void> {
    ApiService.setHeader();
    return ApiService.get('user')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_USERS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.GET_MANAGER_SELECTED_USER](ownerId: string): void {
    this.context.commit(Mutations.SET_MANAGER_SELECTED_USER, ownerId);
  }
}
