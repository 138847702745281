import store from '@/store';
import { Mutations } from '@/store/enums/StoreEnums';
import type { Component } from 'vue';
import { createRouter, createWebHashHistory, type RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/dashboard',
    component: (): Component => import('@/layout/Layout.vue'),
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: (): Component => import('@/views/Dashboard.vue'),
      },
      {
        path: '/cloud',
        name: 'cloud',
        component: (): Component => import('@/views/apps/environments/Cloud.vue'),
      },
      {
        path: '/direct-reports',
        name: 'direct-reports',
        component: (): Component => import('@/views/apps/environments/DirectReports.vue'),
      },
      {
        path: '/library',
        name: 'library',
        component: (): Component => import('@/views/crafted/environments/Library.vue'),
      },
      {
        path: '/my-library',
        name: 'my-library',
        component: (): Component => import('@/views/apps/environments/LibraryDetails.vue'),
      },
      {
        path: '/shared-library',
        name: 'shared-library',
        component: (): Component => import('@/views/apps/environments/SharedLibraryDetails.vue'),
      },
      {
        path: '/tool-template',
        name: 'tool-template',
        component: (): Component => import('@/views/apps/environments/Template.vue'),
      },
      {
        path: '/builder',
        name: 'builder',
        component: (): Component => import('@/views/Builder.vue'),
      },
      {
        path: '/apps/environments',
        name: 'environments',
        component: (): Component => import('@/components/page-layouts/Main.vue'),
        children: [
          {
            path: 'all',
            name: 'environments-all',
            component: (): Component => import('@/views/crafted/environments/All.vue'),
          },
          {
            path: 'direct-reports/:id/view',
            name: 'direct-reports-all',
            component: (): Component => import('@/views/crafted/environments/AllDirectReports.vue'),
          },
        ],
      },
      {
        path: '/apps/environments/details/:id',
        name: 'env',
        component: (): Component => import('@/components/page-layouts/Env.vue'),
        children: [
          {
            path: 'overview',
            name: 'env-overview',
            component: (): Component => import('@/views/apps/environments/details/Overview.vue'),
          },
          {
            path: 'activities',
            name: 'env-activities',
            component: (): Component => import('@/views/apps/environments/details/Activities.vue'),
          },
          {
            path: 'logs',
            name: 'env-logs',
            component: (): Component => import('@/views/apps/environments/details/Logs.vue'),
          },
          {
            path: 'debug',
            name: 'env-debug',
            component: (): Component => import('@/views/apps/environments/details/Debug.vue'),
          },
        ],
      },
      {
        path: '/apps/environments/create',
        name: 'environments-create',
        component: (): Component => import('@/views/crafted/environments/CreateEnvironment.vue'),
      },
      {
        path: '/apps/environments/catalog',
        name: 'environments-catalog',
        component: (): Component => import('@/views/crafted/environments/Catalog.vue'),
      },
    ],
  },
  {
    path: '/',
    component: (): Component => import('@/components/page-layouts/Auth.vue'),
    children: [
      {
        path: '/sign-in',
        name: 'sign-in',
        component: (): Component => import('@/views/crafted/authentication/basic-flow/SignIn.vue'),
      },
    ],
  },
  {
    path: '/code-disclaimer',
    name: 'code-disclaimer',
    component: (): Component => import('@/views/crafted/authentication/CodeDisclaimer.vue'),
  },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: (): Component => import('@/views/crafted/authentication/Error404.vue'),
  },
  {
    path: '/500',
    name: '500',
    component: (): Component => import('@/views/crafted/authentication/Error500.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});
//
export default router;
