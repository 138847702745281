import ApiService from '@/core/services/ApiService';
import JwtService from '@/core/services/JwtService';
import { Environment } from '@/interfaces/Environment';
import { ScheduleInfo, Tenant } from '@/interfaces/Tenant';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { AxiosResponse } from 'axios';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

export interface TenantInfo {
  errors: unknown;
  tenant: Tenant[];
}

@Module
export default class TenantModule extends VuexModule implements TenantInfo {
  errors = {};
  tenant: Tenant[] = [];
  isAliveTenant: Tenant[] = [];
  allTenant = {} as Tenant;
  costTenant = {};
  costAllTenant = {};
  selectedTenant = {} as Tenant;
  updatedTenant = {};
  selectedFeature = '';
  deletedTenant = {};
  tenantName = '';
  tenantDemos: Environment[] = [];
  schedules: ScheduleInfo = {
    schedules: [],
    hasCumulus: false,
  };

  get currentTenant(): Tenant[] {
    return this.tenant;
  }

  // by owner
  get currentIsAliveTenant(): Tenant[] {
    return this.isAliveTenant;
  }
  // by tenant
  get getIsTenantAlive(): Tenant[] {
    return this.isAliveTenant;
  }

  get currentTenantName(): string {
    return this.tenantName;
  }

  get currentTenantDemos(): Environment[] {
    return this.tenantDemos;
  }

  get currentAllTenant(): Tenant {
    return this.allTenant;
  }

  get currentSelectedTenant(): Tenant {
    return this.selectedTenant;
  }

  get currentDeletedTenant(): any {
    return this.deletedTenant;
  }

  get currentSchedules(): ScheduleInfo {
    return this.schedules;
  }

  get getTenantErrors(): any {
    return this.errors;
  }

  get currentSelectedFeature(): string {
    return this.selectedFeature;
  }

  @Mutation
  [Mutations.SET_TENANT](data: Tenant[]): void {
    this.tenant = data;
  }

  @Mutation
  [Mutations.SET_TENANT_NAME](tenantName: string): void {
    this.tenantName = tenantName;
  }

  @Mutation
  [Mutations.SET_SELECTED_FEATURE](feature: string): void {
    this.selectedFeature = feature;
  }

  @Mutation
  [Mutations.SET_TENANT_DEMOS](tenantDemos: Environment[]): void {
    this.tenantDemos = tenantDemos;
  }

  @Mutation
  [Mutations.SET_ISALIVE_TENANT](data: Tenant[]): void {
    this.isAliveTenant = data;
  }

  @Mutation
  [Mutations.SET_ALL_TENANT](data: Tenant): void {
    this.allTenant = data;
  }

  @Mutation
  [Mutations.SET_TENANT_DEPLOY](data: Tenant[]): void {
    this.tenant = data;
  }

  @Mutation
  [Mutations.SET_SCALED_TENANT](tenant: Tenant): void {
    this.selectedTenant = tenant;
  }

  @Mutation
  [Mutations.SET_DELETED_TENANT](data: any): void {
    this.deletedTenant = data;
  }

  @Mutation
  [Mutations.SET_TENANT_SPIN_SCHEDULE](schedules: ScheduleInfo): void {
    this.schedules = schedules;
  }

  @Mutation
  [Mutations.SET_SELECTED_TENANT](tenant: Tenant): void {
    this.selectedTenant = tenant;
  }

  @Mutation
  [Mutations.SET_UPDATE_TENANT](tenant: Tenant): void {
    this.updatedTenant = tenant;
  }

  @Mutation
  [Mutations.SET_TENANT_ERROR](error: any): void {
    this.errors = { ...error };
  }

  // used for pipeline if needed
  @Action
  [Actions.CREATE_TENANT](body: any): Promise<void> {
    return ApiService.post('tenant/create', body.data)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TENANT_DEPLOY, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_TENANT_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  // used for encore
  @Action
  [Actions.DEPLOY_TENANT](body: any): Promise<void> {
    return ApiService.post('tenant/deploy', body.data)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TENANT_DEPLOY, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_TENANT_ERROR, response.data.errors);

        if (response.status === 403) {
          const systemErr = [{ msg: 'Oops.  Looks like you reached your limit.  Please contact your SE Partner.' }];
          this.context.commit(Mutations.SET_TENANT_ERROR, systemErr);
        }
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.GET_ALL_TENANT](): Promise<void> {
    return ApiService.get('tenant')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_TENANT, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_TENANT_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.GET_ISALIVE_TENANT_BY_OWNER](body: any): Promise<void> {
    ApiService.setHeader();
    return ApiService.get('tenant/ownerLive/' + body.email)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ISALIVE_TENANT, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_TENANT_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }
  // OLD - JOSH ADDED NEW API TO MAKE ISALIVE CALL AND RETURN REAL-TIME STATUS
  @Action
  [Actions.GET_TENANT_BY_OWNER](body: any): Promise<void> {
    ApiService.setHeader();
    return ApiService.get('tenant/owner/' + body.email)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TENANT, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_TENANT_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.SCALE_DOWN_TENANT_BY_NAME](body: any): Promise<void> {
    ApiService.setHeader();
    return ApiService.post('tenant/spindown/' + body.data.tenant, body)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SCALED_TENANT, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_TENANT_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.SCALE_UP_TENANT_BY_NAME](body: any): Promise<void> {
    ApiService.setHeader();
    return ApiService.post('tenant/spinup/' + body.data.tenant, body)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SCALED_TENANT, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_TENANT_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.DELETE_TENANT_BY_NAME](body: any): Promise<void> {
    ApiService.setHeader();
    return ApiService.delete('tenant/delete/' + body.data.tenant)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DELETED_TENANT, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_TENANT_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.GET_TENANT_SPIN_SCHEDULE](tenantName: string): Promise<void> {
    ApiService.setHeader();
    return ApiService.get(`tenant/${tenantName}/schedule`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TENANT_NAME, tenantName);
        this.context.commit(Mutations.SET_TENANT_SPIN_SCHEDULE, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_TENANT_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.GET_TENANT_DEMOS](tenantName: string): Promise<void> {
    ApiService.setHeader();
    return ApiService.get(`tenant/${tenantName}/demo`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TENANT_DEMOS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_TENANT_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.PUT_TENANT_SPIN_SCHEDULE](scheduleInfo: any): Promise<void | AxiosResponse<any, any>> {
    ApiService.setHeader();
    return ApiService.put(`tenant/${scheduleInfo.tenant}/schedule`, scheduleInfo.payload).catch(({ response }) => {
      this.context.commit(Mutations.SET_TENANT_ERROR, response.data.errors);
      if (response.status === 401) {
        JwtService.destroyToken();
      }
    });
  }

  // moving cumulus functions

  @Action
  [Actions.SELECTED_TENANT](tenant: Tenant): void {
    this.context.commit(Mutations.SET_SELECTED_TENANT, tenant);
  }

  @Action
  [Actions.UPDATE_TENANT_BY_NAME](body: any): Promise<void> {
    ApiService.setHeader();
    return ApiService.post('tenant/update/' + body.tenant, body)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_UPDATE_TENANT, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_TENANT_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.ADD_TENANT_FEATURE](tenantFeature: any): Promise<void | AxiosResponse<any, any>> {
    ApiService.setHeader();
    return ApiService.post(`tenant/${tenantFeature.tenant}/features`, tenantFeature.featurePayload).catch(({ response }) => {
      this.context.commit(Mutations.SET_TENANT_ERROR, response.data.errors);
      if (response.status === 401) {
        JwtService.destroyToken();
      }
    });
  }
}
