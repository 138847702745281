import { CookieComponent } from '@/assets/ts/components/_CookieComponent';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { App } from 'vue';
import VueAxios from 'vue-axios';

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>): void {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    if (process.env.VUE_APP_ENV === 'production') {
      ApiService.vueInstance.axios.defaults.baseURL = 'https://' + location.host + '/api/';
    } else {
      ApiService.vueInstance.axios.defaults.baseURL = 'http://localhost:8081/api/';
    }
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common['Accept'] = 'application/json';
    ApiService.vueInstance.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

    if (process.env.VUE_APP_ENV === 'production') {
      ApiService.vueInstance.axios.defaults.headers.common['Authorization'] = `Bearer ${CookieComponent.get('secure_access_token')}`;
    }
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(resource: string, slug = '' as string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static postparams(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(resource: string, slug: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(`${resource}`);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static deleteparams(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(`${resource}`, params);
  }
}

export default ApiService;
