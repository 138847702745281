import ApiService from '@/core/services/ApiService';
import JwtService from '@/core/services/JwtService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

export interface Opportunity {
  Id: string;
  Name: string;
}

export interface MAE {
  Id: string;
  Name: string;
  Region: string;
}
export interface SalesforceInfo {
  errors: unknown;
  opp: Opportunity;
  mae: MAE;
}

@Module
export default class SalesforceModule extends VuexModule implements SalesforceInfo {
  errors = {};
  opp = {} as Opportunity;
  mae = {} as MAE;

  /**
   * Get current mae object
   * @returns Mae
   */
  get currentMAE(): MAE {
    return this.mae;
  }

  get currentOpportunity(): Opportunity {
    return this.opp;
  }

  @Mutation
  [Mutations.SET_ERROR](error: any): void {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_SFDC_MAE](mae: MAE): void {
    this.mae = mae;
  }

  @Mutation
  [Mutations.SET_SFDC_OPPORTUNITY](opp: Opportunity): void {
    this.opp = opp;
  }

  @Action
  [Actions.SFDC_MAE](): Promise<void> {
    return ApiService.get('sfdc/mae')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SFDC_MAE, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        if (response.status === 401) {
          //JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.SFDC_OPPORTUNITY_BY_MAE](mae: string): Promise<void> {
    return ApiService.get('sfdc/opportunity', mae)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SFDC_OPPORTUNITY, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }
}
