enum Actions {
  // action types
  ADD_BODY_CLASSNAME = 'addBodyClassName',
  REMOVE_BODY_CLASSNAME = 'removeBodyClassName',
  ADD_BODY_ATTRIBUTE = 'addBodyAttribute',
  REMOVE_BODY_ATTRIBUTE = 'removeBodyAttribute',
  ADD_CLASSNAME = 'addClassName',
  VERIFY_AUTH = 'verifyAuth',
  LOGIN = 'login',
  LOGINSSO = 'sso',
  LOGINSLEEKSSO = 'loginSleekSSO',
  LOGINLOCAL = 'local',
  LOGOUT = 'logout',
  UPDATE_USER = 'updateUser',
  SET_BREADCRUMB_ACTION = 'setBreadcrumbAction',
  // encore
  DEPLOY_DEMO = 'deployDemo',
  GET_DEPLOYED_PROGRESS = 'getDeployedProgress',
  GET_ALL_ENVIRONMENTS = 'getAllEnvironments',
  GET_ENVIRONMENT_LIST_BY_OWNER = 'getEnvListByOwner',
  GET_SELECTED_ENVIRONMENT_BY_ID = 'getSelectedListById',
  DELETE_ENVIRONMENT_BY_ID = 'deleteEnvById',
  EXTEND_ENVIRONMENT_BY_ID = 'extendEnvById',
  EXTEND_SELECTED_ENVIRONMENT = 'extendSelectedEnv',
  SCALE_ENVIRONMENT_BY_ID = 'scaleEnvById',
  SCALE_DOWN_TENANT_BY_NAME = 'scaleDownTenantByName',
  SCALE_UP_TENANT_BY_NAME = 'scaleUpTenantByName',
  DELETE_TENANT_BY_NAME = 'deleteTenantByName',
  GET_SECRETS_BY_ID = 'getSecretsById',
  COST_OF_TENANT_BY_NAME = 'costTenantByName',
  COST_OF_ALL_TENANT_BY_NAME = 'costAllTenantByName',
  GET_CATALOG = 'getCatalog',
  CREATE_TENANT = 'createTenant',
  DEPLOY_TENANT = 'deployTenant', // deprecated
  GET_ALL_TENANT = 'getAllTenant',
  GET_ISALIVE_TENANT_BY_OWNER = 'getIsAliveTenantByOwner',
  GET_TENANT_BY_OWNER = 'getTenantByOwner',
  GET_TENANT_BY_NAME = 'getTenantByOwner',
  GET_FRODO_JOURNEY_BY_OWNER_AND_ENV = 'getJourneyByOwnerAndEnv',
  GET_FRODO_SCRIPT_BY_OWNER_AND_ENV = 'getScriptByOwnerAndEnv',
  GET_FRODO_JOURNEYS_BY_OWNER = 'getFrodoJourneysByOwner',
  GET_FRODO_SCRIPTS_BY_OWNER = 'getFrodoScriptsByOwner',
  GET_FRODO_SHARED_JOURNEYS = 'getFrodoSharedJourneys',
  GET_FRODO_SHARED_SCRIPTS = 'getFrodoSharedScripts',
  EXPORT_FRODO_BY_OWNER_AND_ENV = 'exportFrodoByOwnerAndEnv',
  IMPORT_FRODO_BY_OWNER_AND_ENV = 'importFrodoByOwnerAndEnv',
  SELECT_ASSETS_FRODO_BY_TENANT = 'selectAssetsFrodoByTenant',
  UPDATE_FRODO_SHARED = 'updateFrodoShared',
  DELETE_FRODO_SHARED = 'deleteFrodoShared',
  GET_DEPLOYED_PODS = 'getDeployedPods',
  GET_ENVIRONMENT_BACKUPS_BY_ID = 'getEnvironmentBackupsById',
  BACKUP_ENVIRONMENT_BY_ID = 'backupEnvironmentById',
  RESTORE_ENVIRONMENT_BY_ID = 'restoreEnvironmentById',
  SFDC_MAE = 'getSFDCMAE',
  SFDC_OPPORTUNITY_BY_MAE = 'getSFDCOpportunityByMAE',
  GET_TENANT_SPIN_SCHEDULE = 'getTenantSpinSchedule',
  GET_TENANT_DEMOS = 'getTenantDemos',
  PUT_TENANT_SPIN_SCHEDULE = 'putTenantSpinSchedule',
  SELECTED_TENANT = 'getSelectedTenant',
  TENANT_IS_ALIVE = 'getIsAliveTenant',
  UPDATE_TENANT_BY_NAME = 'updateTenantByName',
  GET_ALL_USERS = 'getAllUsers',
  ADD_TENANT_FEATURE = 'addTenantFeature',
  GET_MANAGER_SELECTED_USER = 'getManagerSelectedUser',
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
  PURGE_AUTH = 'logOut',
  SET_AUTH = 'setAuth',
  SET_USER = 'setUser',
  SET_USER_GROUPS = 'SetUserGroups',
  SET_SLEEK_USER = 'setSleekUser',
  SET_ERROR = 'setError',
  SET_BREADCRUMB_MUTATION = 'setBreadcrumbMutation',
  SET_LAYOUT_CONFIG = 'setLayoutConfig',
  RESET_LAYOUT_CONFIG = 'resetLayoutConfig',
  OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig',
  OVERRIDE_PAGE_LAYOUT_CONFIG = 'overridePageLayoutConfig',
  // encore
  SET_DEMO_ENV_BY_OWNER = 'setDemoEnvByOwner',
  SET_DEPLOY = 'setDeploy',
  SET_DEPLOYED_PROGRESS = 'setDeployedProgress',
  RESET_DEPLOYED_PROGRESS = 'resetDeployedProgress',
  SET_DEPLOY_ERROR = 'setDeployError',
  SET_ALL_ENVIRONMENTS = 'setAllEnvironments',
  SET_ENVIRONMENT_LIST_BY_OWNER = 'setEnvListByOwner',
  SET_SELECTED_ENVIRONMENT_BY_OWNER = 'setSelectedEnvByOwner',
  SET_ENV_ERROR = 'setEnvError',
  SET_ENV_ACCESS_ERROR = 'setEnvAccessError',
  SET_DELETED_ENV = 'setDeletedEnv',
  SET_EXTENDED_ENV = 'setExtendedEnv',
  SET_SELECTED_EXTENDED_ENV = 'setSelectedExtendedEnv',
  RESET_SELECTED_ENV = 'resetSelectedEnv',
  RESET_SELECTED_SECRETS = 'resetSelectedSecrets',
  SET_SCALED_ENV = 'setScaledEnv',
  SET_SCALED_TENANT = 'setScaledTenant',
  SET_DELETED_TENANT = 'setDeletedTenant',
  SET_SECRETS = 'setSecrets',
  SET_COST_TENANT = 'setCostTenant',
  SET_COST_ALL_TENANT = 'setCostAllTenant',
  SET_CATALOG = 'setCatalog',
  SET_ISALIVE_TENANT = 'setIsAliveTenant',
  SET_TENANT = 'setTenant',
  SET_ALL_TENANT = 'setAllTenant',
  SET_TENANT_DEPLOY = 'setTenantDeploy',
  SET_TENANT_ERROR = 'setTenantError',
  SET_FRODO_JOURNEY_OWNER_ENV = 'setJourneyOwnerEnv',
  SET_FRODO_SCRIPT_OWNER_ENV = 'setScriptOwnerEnv',
  SET_FRODO_JOURNEYS_BY_OWNER = 'setFrodoJourneysByOwner',
  SET_FRODO_SCRIPTS_BY_OWNER = 'setFrodoScriptsByOwner',
  SET_FRODO_SHARED_JOURNEYS = 'setFrodoSharedJourneys',
  SET_FRODO_SHARED_SCRIPTS = 'setFrodoSharedScripts',
  SET_IMPORT_FRODO_BY_TENANT = 'setImportFrodoTenant',
  RESET_FRODO_ENV = 'resetFrodoEnv',
  SET_FRODO_ERROR = 'setFrodoError',
  SET_EXPORT_FRODO_ENV = 'setExportFrodoEnv',
  SET_IMPORT_FRODO_ENV = 'setImportFrodoEnv',
  SET_FRODO_SHARED = 'setFrodoShared',
  SET_FRODO_DELETE = 'setFrodoDelete',
  SET_DEPLOYED_PODS = 'setDeployedPods',
  RESET_DEPLOYED_PODS = 'resetDeployedPods',
  SET_BACKUP_LIST_ENVIRONMENT_BY_ID = 'setBackupListEnvironmentById',
  SET_BACKUP_ENVIRONMENT_BY_ID = 'setBackupEnvironmentById',
  BACKUP_SELECTED_ENV = 'backupSelectedEnv',
  SET_SELECTED_RESTORE = 'setSelectedRestore',
  SET_RESTORE_ENVIRONMENT_BY_ID = 'setRestoreEnvironmentById',
  SET_SFDC_MAE = 'setMAE',
  SET_SFDC_OPPORTUNITY = 'setOpportunity',
  SET_SELECTED_CATALOG = 'setSelectedCatalog',
  SET_TENANT_SPIN_SCHEDULE = 'setTenantSpinSchedule',
  SET_TENANT_NAME = 'setTenantName',
  SET_TENANT_DEMOS = 'setTenantDemos',
  SET_SELECTED_FEATURE = 'setSelectedFeature',
  SET_SELECTED_TENANT = 'setSelectedTenant',
  SET_TENANT_IS_ALIVE = 'setIsAliveSelectedTenant',
  SET_UPDATE_TENANT = 'setUpdateSelectedTenant',
  SET_ALL_USERS = 'setAllUsers',
  SET_MANAGER_SELECTED_USER = 'setManagerSelectedUser',
}

export { Actions, Mutations };
