import ApiService from '@/core/services/ApiService';
import JwtService from '@/core/services/JwtService';
import {
  AllEnvsItem,
  Environment,
  EnvironmentBackup,
  EnvironmentOwner,
  EnvironmentStatus,
  EnvironmentSummary,
  ExtendEnvBody,
  RestoreEnvBody,
  ScaleEnvBody,
} from '@/interfaces/Environment';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

export interface EnvironmentInfo {
  errors: unknown;
  env: EnvironmentSummary;
}

@Module
export default class EnvironmentModule extends VuexModule implements EnvironmentInfo {
  errors = {};
  accessErrors = {};
  env = {} as EnvironmentSummary;
  selectedEnv = {} as Environment;
  allEnv: AllEnvsItem[] = [];
  selectedExtendEnv = {} as Environment;
  selectedEnvSecrets: string[] = [];
  selectedEnvBackup: EnvironmentBackup[] = [];
  selectedEnvListBackup = {} as EnvironmentStatus;
  selectedEnvRestore = '';
  envRestore = {} as EnvironmentStatus;

  get currentAllEnv(): AllEnvsItem[] {
    return this.allEnv;
  }

  get currentSelectedEnv(): Environment {
    return this.selectedEnv;
  }

  get currentSelectedExtendEnv(): Environment {
    return this.selectedExtendEnv;
  }

  get currentSecretsByEnv(): string[] {
    return this.selectedEnvSecrets;
  }

  get currentListEnvByOwner(): EnvironmentSummary {
    return this.env;
  }

  get currentBackupsByEnv(): EnvironmentBackup[] {
    return this.selectedEnvBackup;
  }

  get currentBackupListByEnv(): EnvironmentStatus {
    return this.selectedEnvListBackup;
  }

  get currentSelectedBackupForRestore(): string {
    return this.selectedEnvRestore;
  }

  get getEnvironmentErrors(): any {
    return this.errors;
  }

  get getEnvironmentAccessErrors(): any {
    return this.accessErrors;
  }

  @Mutation
  [Mutations.SET_ALL_ENVIRONMENTS](env: AllEnvsItem[]): void {
    this.allEnv = env;
  }

  @Mutation
  [Mutations.SET_ENVIRONMENT_LIST_BY_OWNER](env: EnvironmentSummary): void {
    this.env = env;
  }

  @Mutation
  [Mutations.SET_DELETED_ENV](env: Environment): void {
    this.selectedEnv = env;
  }

  @Mutation
  [Mutations.SET_EXTENDED_ENV](env: Environment): void {
    this.selectedEnv = env;
  }

  @Mutation
  [Mutations.SET_SELECTED_EXTENDED_ENV](env: Environment): void {
    this.selectedExtendEnv = env;
  }

  @Mutation
  [Mutations.SET_SELECTED_ENVIRONMENT_BY_OWNER](env: Environment): void {
    this.selectedEnv = env;
  }

  @Mutation
  [Mutations.SET_SCALED_ENV](env: Environment): void {
    this.selectedEnv = env;
  }

  @Mutation
  [Mutations.SET_SECRETS](secrets: string[]): void {
    this.selectedEnvSecrets = secrets;
  }

  @Mutation
  [Mutations.SET_BACKUP_ENVIRONMENT_BY_ID](data: EnvironmentBackup[]): void {
    this.selectedEnvBackup = data;
  }

  @Mutation
  [Mutations.SET_BACKUP_LIST_ENVIRONMENT_BY_ID](data: EnvironmentStatus): void {
    this.selectedEnvListBackup = data;
  }

  @Mutation
  [Mutations.SET_RESTORE_ENVIRONMENT_BY_ID](data: EnvironmentStatus): void {
    this.envRestore = data;
  }

  @Mutation
  [Mutations.SET_SELECTED_RESTORE](env: string): void {
    this.selectedEnvRestore = env;
  }

  @Mutation
  [Mutations.RESET_SELECTED_ENV](): void {
    this.selectedEnv = Object.assign({});
    this.selectedExtendEnv = {} as Environment;
  }

  @Mutation
  [Mutations.RESET_SELECTED_SECRETS](): void {
    this.selectedEnvSecrets = [];
  }

  @Mutation
  [Mutations.SET_ENV_ACCESS_ERROR](error: any): void {
    this.accessErrors = { ...error };
  }

  @Mutation
  [Mutations.SET_ENV_ERROR](error: any): void {
    this.errors = { ...error };
  }

  @Action
  [Actions.GET_ALL_ENVIRONMENTS](): Promise<void> {
    ApiService.setHeader();
    return ApiService.get('demo/all')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_ENVIRONMENTS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ENV_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.GET_ENVIRONMENT_LIST_BY_OWNER](owner: EnvironmentOwner): Promise<void> {
    ApiService.setHeader();
    return ApiService.get('demo/owner/' + owner._id)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ENVIRONMENT_LIST_BY_OWNER, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ENV_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.GET_SELECTED_ENVIRONMENT_BY_ID](id: string): Promise<void> {
    ApiService.setHeader();
    return ApiService.get('demo/id/' + id)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SELECTED_ENVIRONMENT_BY_OWNER, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ENV_ERROR, response.data.errors);
        if (response.status === 500) {
          const systemErr = [{ msg: 'System Access Error' }];
          this.context.commit(Mutations.SET_ENV_ACCESS_ERROR, systemErr);
        }
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.SCALE_ENVIRONMENT_BY_ID](body: ScaleEnvBody): Promise<void> {
    ApiService.setHeader();
    return ApiService.post('demo/scale/' + body.data.id, body)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SCALED_ENV, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ENV_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.DELETE_ENVIRONMENT_BY_ID](id: string): Promise<void> {
    ApiService.setHeader();
    return ApiService.delete('demo/' + id)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DELETED_ENV, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ENV_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.EXTEND_ENVIRONMENT_BY_ID](body: { data: ExtendEnvBody }): Promise<void> {
    ApiService.setHeader();
    return ApiService.put('demo/extend/' + body.data.id, body)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_EXTENDED_ENV, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ENV_ERROR, response.data.errors);
        if (response.status === 403) {
          const systemErr = [{ msg: 'Oops.  Unauthorized.  Contact TSEC Support' }];
          this.context.commit(Mutations.SET_ENV_ERROR, systemErr);
        }
        if (response.status === 500) {
          const systemErr = [{ msg: 'System Error.  Contact TSEC Support' }];
          this.context.commit(Mutations.SET_ENV_ERROR, systemErr);
        }
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.GET_SECRETS_BY_ID](id: string): Promise<void> {
    ApiService.setHeader();
    return ApiService.get('demo/secrets/' + id)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SECRETS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ENV_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.EXTEND_SELECTED_ENVIRONMENT](body: ExtendEnvBody): void {
    this.context.commit(Mutations.SET_SELECTED_EXTENDED_ENV, body);
  }

  @Action
  [Actions.GET_ENVIRONMENT_BACKUPS_BY_ID](id: string): Promise<void> {
    ApiService.setHeader();
    return ApiService.get('demo/backup/' + id)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_BACKUP_ENVIRONMENT_BY_ID, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ENV_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.BACKUP_ENVIRONMENT_BY_ID](body: { data: { id: string } }): Promise<void> {
    ApiService.setHeader();
    return ApiService.post('demo/backup/' + body.data.id, body)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_BACKUP_LIST_ENVIRONMENT_BY_ID, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ENV_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.RESTORE_ENVIRONMENT_BY_ID](body: RestoreEnvBody): Promise<void> {
    ApiService.setHeader();
    return ApiService.post('demo/restore/' + body.data.id, body)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESTORE_ENVIRONMENT_BY_ID, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ENV_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }
}
