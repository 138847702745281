import ApiService from '@/core/services/ApiService';
import JwtService from '@/core/services/JwtService';
import { Deploy, DeployDemoBody, DeployProgress, Pod } from '@/interfaces/Deploy';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

export interface DeployInfo {
  errors: unknown;
  deploy: Deploy;
}

@Module
export default class DeployModule extends VuexModule implements DeployInfo {
  errors = {};
  deploy = {} as Deploy;
  pods: Pod[] = [];
  progress = {} as DeployProgress;

  get currentDeploy(): Deploy {
    return this.deploy;
  }

  get currentDeployedPods(): Pod[] {
    return this.pods;
  }

  get getDeployProgress(): DeployProgress {
    return this.progress;
  }

  get getDeployErrors(): object {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_DEPLOY](deploy: Deploy): void {
    this.deploy = deploy;
  }

  @Mutation
  [Mutations.SET_DEPLOYED_PODS](data: Pod[]): void {
    this.pods = data;
  }

  @Mutation
  [Mutations.RESET_DEPLOYED_PODS](): void {
    this.pods = [];
  }

  @Mutation
  [Mutations.SET_DEPLOYED_PROGRESS](data: DeployProgress): void {
    this.progress = data;
  }

  @Mutation
  [Mutations.RESET_DEPLOYED_PROGRESS](): void {
    this.progress = {} as DeployProgress;
  }

  @Mutation
  [Mutations.SET_DEPLOY_ERROR](error: object): void {
    this.errors = { ...error };
  }

  @Action
  [Actions.DEPLOY_DEMO](body: DeployDemoBody): Promise<void> {
    return ApiService.post('demo/deploy/' + body.data.catalog_id, body)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DEPLOY, data);

        this.context.commit(Mutations.SET_DEPLOY_ERROR, {});
      })
      .catch(({ response }) => {
        if (response.status === 400) {
          this.context.commit(Mutations.SET_DEPLOY_ERROR, response.data.errors);
        }
        if (response.status === 403) {
          const systemErr = [{ msg: 'Oops.  Looks like you reached your limit.  Please contact your SE Partner.' }];
          this.context.commit(Mutations.SET_DEPLOY_ERROR, systemErr);
        }
        if (response.status === 500) {
          const systemErr = [{ msg: 'System Error.  Contact TSEC Support' }];
          this.context.commit(Mutations.SET_DEPLOY_ERROR, systemErr);
        }

        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.GET_DEPLOYED_PODS](demoId: string): Promise<void> {
    return ApiService.get('demo/pods/' + demoId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DEPLOYED_PODS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_DEPLOY_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.GET_DEPLOYED_PROGRESS](demoId: string): Promise<void> {
    return ApiService.get('demo/progress/' + demoId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DEPLOYED_PROGRESS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_DEPLOY_ERROR, response.data.errors);
        if (response.status === 400) {
          this.context.commit(Mutations.SET_DEPLOY_ERROR, response.data.errors);
        }
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }
}
