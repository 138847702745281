import { CookieComponent } from '@/assets/ts/components/_CookieComponent';
import router from '@/router';

const ID_TOKEN_KEY = 'id_token' as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  // window.localStorage.removeItem(ID_TOKEN_KEY);

  window.localStorage.removeItem('userInfo');
  window.localStorage.removeItem('isAuthenticated');
  CookieComponent.delete('id_token');
  CookieComponent.delete('secure_access_token');

  router.push({ name: 'sign-in' });
};

export default { getToken, saveToken, destroyToken };
