import { createStore } from 'vuex';
import { config } from 'vuex-module-decorators';

import AuthModule from '@/store/modules/AuthModule';
import BodyModule from '@/store/modules/BodyModule';
import BreadcrumbsModule from '@/store/modules/BreadcrumbsModule';
import ConfigModule from '@/store/modules/ConfigModule';

import DeployModule from '@/store/modules/DeployModule';
import EnvironmentModule from '@/store/modules/EnvironmentModule';
import TenantModule from '@/store/modules/TenantModule';
import FrodoModule from '@/store/modules/FrodoModule';
import CatalogModule from '@/store/modules/CatalogModule';
import SalesforceModule from '@/store/modules/SalesforceModule';

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    DeployModule,
    EnvironmentModule,
    TenantModule,
    FrodoModule,
    CatalogModule,
    SalesforceModule,
  },
});

export default store;
