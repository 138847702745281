import ApiService from '@/core/services/ApiService';
import JwtService from '@/core/services/JwtService';
import { LibraryImportData, LibraryItem, LibraryPayload } from '@/interfaces/Library';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FrodoInfo {}

@Module
export default class FrodoModule extends VuexModule implements FrodoInfo {
  frodoErrors = {};
  frodoJourney: LibraryItem[] = [];
  frodoScript: LibraryItem[] = [];
  frodoExport = '';
  frodoImport = '';
  frodoSharedJourney = {} as LibraryPayload;
  frodoSharedScript = {} as LibraryPayload;
  frodoUpdate = {} as LibraryPayload;
  frodoImportShared = {} as LibraryImportData;

  get currentFrodoScripts(): LibraryItem[] {
    return this.frodoScript;
  }

  get currentFrodoJourneys(): LibraryItem[] {
    return this.frodoJourney;
  }
  get currentFrodoSharedJourneys(): LibraryPayload {
    return this.frodoSharedJourney;
  }

  get currentFrodoSharedScripts(): LibraryPayload {
    return this.frodoSharedScript;
  }

  get currentFrodoExport(): string {
    return this.frodoExport;
  }

  get currentFrodoImport(): string {
    return this.frodoImport;
  }

  get selectedSharedFrodoImport(): LibraryImportData {
    return this.frodoImportShared;
  }

  get getFrodoErrors(): any {
    return this.frodoErrors;
  }

  @Mutation
  [Mutations.SET_FRODO_JOURNEY_OWNER_ENV](data: LibraryItem[]): void {
    this.frodoJourney = data;
  }

  @Mutation
  [Mutations.SET_FRODO_SCRIPT_OWNER_ENV](data: LibraryItem[]): void {
    this.frodoScript = data;
  }

  @Mutation
  [Mutations.SET_FRODO_SHARED](data: LibraryPayload): void {
    this.frodoUpdate = data;
  }

  @Mutation
  [Mutations.SET_FRODO_DELETE](data: LibraryPayload): void {
    this.frodoUpdate = data;
  }

  @Mutation
  [Mutations.SET_FRODO_SHARED_JOURNEYS](data: LibraryPayload): void {
    this.frodoSharedJourney = data;
  }

  @Mutation
  [Mutations.SET_FRODO_SHARED_SCRIPTS](data: LibraryPayload): void {
    this.frodoSharedScript = data;
  }

  @Mutation
  [Mutations.SET_EXPORT_FRODO_ENV](data: string): void {
    this.frodoExport = data;
  }

  @Mutation
  [Mutations.SET_IMPORT_FRODO_ENV](data: string): void {
    this.frodoImport = data;
  }

  @Mutation
  [Mutations.SET_FRODO_JOURNEYS_BY_OWNER](data: LibraryItem[]): void {
    this.frodoJourney = data;
  }

  @Mutation
  [Mutations.SET_FRODO_SCRIPTS_BY_OWNER](data: LibraryItem[]): void {
    this.frodoScript = data;
  }

  @Mutation
  [Mutations.SET_IMPORT_FRODO_BY_TENANT](data: LibraryImportData): void {
    this.frodoImportShared = data;
  }

  @Mutation
  [Mutations.RESET_FRODO_ENV](): void {
    this.frodoJourney = [];
    this.frodoScript = [];
  }

  @Mutation
  [Mutations.SET_FRODO_ERROR](error: any): void {
    this.frodoErrors = { ...error };
  }

  @Action
  [Actions.GET_FRODO_JOURNEY_BY_OWNER_AND_ENV](frodo: any): Promise<void> {
    ApiService.setHeader();
    return ApiService.get('frodo/' + frodo.ownerId + '/journey/' + frodo.envId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_FRODO_JOURNEY_OWNER_ENV, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_FRODO_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.GET_FRODO_SCRIPT_BY_OWNER_AND_ENV](frodo: any): Promise<void> {
    ApiService.setHeader();
    return ApiService.get('frodo/' + frodo.ownerId + '/script/' + frodo.envId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_FRODO_SCRIPT_OWNER_ENV, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_FRODO_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.UPDATE_FRODO_SHARED](body: any): Promise<void> {
    return ApiService.put('frodo', body)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_FRODO_SHARED, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_FRODO_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.DELETE_FRODO_SHARED](body: any): Promise<void> {
    return ApiService.deleteparams('frodo', body)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_FRODO_DELETE, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_FRODO_ERROR, response.data.errors);
        if (response.status === 400) {
          this.context.commit(Mutations.SET_FRODO_ERROR, response.data.errors);
        }
        if (response.status === 500) {
          const systemErr = [{ msg: 'System Error.  Contact TSEC Support' }];
          this.context.commit(Mutations.SET_FRODO_ERROR, systemErr);
        }
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.EXPORT_FRODO_BY_OWNER_AND_ENV](body: any): Promise<void> {
    return ApiService.post('frodo/export', body)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_EXPORT_FRODO_ENV, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_FRODO_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.IMPORT_FRODO_BY_OWNER_AND_ENV](body: any): Promise<void> {
    return ApiService.post('frodo/import', body)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IMPORT_FRODO_ENV, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_FRODO_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.GET_FRODO_JOURNEYS_BY_OWNER](frodo: any): Promise<void> {
    ApiService.setHeader();
    return ApiService.get('frodo/journey/' + frodo.ownerId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_FRODO_JOURNEYS_BY_OWNER, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_FRODO_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.GET_FRODO_SCRIPTS_BY_OWNER](frodo: any): Promise<void> {
    ApiService.setHeader();
    return ApiService.get('frodo/script/' + frodo.ownerId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_FRODO_SCRIPTS_BY_OWNER, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_FRODO_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.GET_FRODO_SHARED_JOURNEYS](): Promise<void> {
    ApiService.setHeader();
    return ApiService.get('frodo/journey')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_FRODO_SHARED_JOURNEYS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_FRODO_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.GET_FRODO_SHARED_SCRIPTS](): Promise<void> {
    ApiService.setHeader();
    return ApiService.get('frodo/script')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_FRODO_SHARED_SCRIPTS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_FRODO_ERROR, response.data.errors);
        if (response.status === 401) {
          JwtService.destroyToken();
        }
      });
  }

  @Action
  [Actions.SELECT_ASSETS_FRODO_BY_TENANT](data: LibraryImportData): void {
    this.context.commit(Mutations.SET_IMPORT_FRODO_BY_TENANT, data);
  }
}
