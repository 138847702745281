import { DrawerComponent, MenuComponent, ScrollComponent, StickyComponent, SwapperComponent, ToggleComponent } from '@/assets/ts/components';

/**
 * @description Initialize KeenThemes custom components
 */
const initializeComponents = (): void => {
  setTimeout(() => {
    ToggleComponent.bootstrap();
    StickyComponent.bootstrap();
    MenuComponent.bootstrap();
    ScrollComponent.bootstrap();
    DrawerComponent.bootstrap();
    SwapperComponent.bootstrap();
  }, 0);
};

/**
 * @description Reinitialize KeenThemes custom components
 */
const reinitializeComponents = (): void => {
  setTimeout(() => {
    ToggleComponent.reinitialization();
    StickyComponent.reInitialization();
    MenuComponent.reinitialization();
    reinitializeScrollComponent().then(() => {
      ScrollComponent.updateAll();
    });
    DrawerComponent.reinitialization();
    SwapperComponent.reinitialization();
  }, 0);
};

const reinitializeScrollComponent = async (): Promise<void> => {
  await ScrollComponent.reinitialization();
};

export { initializeComponents, reinitializeComponents, reinitializeScrollComponent };
